<script>
import Select2 from 'vue3-select2-component';
import useVuelidate from "@vuelidate/core";
import {
    required,
    helpers,
} from "@vuelidate/validators";
import axios from 'axios';
import _ from 'lodash';
import CryptoJS from 'crypto-js';
import Footer from "@/components/footer/footer.vue";
import { countries } from '../applicant/country.js';


export default {
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            app_URL: process.env.VUE_APP_URL,
            resizedImageDataUrl: null,
            showErrorUpload: false,
            submitted: false,
            disclosureForm: {
                fullName: '',
                residentialAddress: '',
                countryIssuance: '',
                dateOfBirth: '',
                gender: "",
                countryBirth: "",
                email: "",
                maritalStatus: "",
                placeIssue: "",
                height: "",
                weight: "",
                occupation: "",

            },
            signOfExaminer: "",
            stampOfExaminer: '',
            countryOption: countries,

            formD3: {
                healthProblemsCk: "",
                healthProblems: "",
                hospitalisedCk: "",
                hospitalised: "",
                visitDocCk: "",
                visitDoc: "",
                Tuberculosis: "",
                leprosy: "",
                hepititis: "",
                typhoid: "",
                aids: "",
                geneticDisorder: "",
                earDisease: "",
                eyeDisease: "",
                cancerousDisease: "",
                headache: "",
                mentalDisorders: "",
                allergies: "",
                hypertension: "",
                intestinalDisease: "",
                communicableDisease: "",
                urinaryDisease: "",
                venerealDisease: "",
                boneDisease: "",
                isSkinDisease: "",
                cosmeticOperation: "",
                otherDisorder: "",
                aboveDetails: "",
                signSkinDisease: "",
                skinDisease: "",
                signRespiratorySys: "",
                respiratorySys: "",
                signCardiovascularSys: "",
                cardiovascularSys: "",
                signDigestiveOrgans: "",
                digestiveOrgans: "",
                signNervousSys: "",
                nervousSys: "",
                signUrogenital: "",
                urogenitalProtein: "",
                urogenitalSugar: "",
                urogenitalSediment: "",
                signMusculoskeletalSys: "",
                musculoskeletalSys: "",
                signEndocrineSys: "",
                endocrineSys: "",
                signVarious: "",
                various: "",
                // isFinalEvaluation: "",
                finalEvaluation: "",
                // isComments: "",
                comments: "",
                fNameMedicalExam: "",
                telephoneNo: "",
                faxNo: "",
                examEmail: "",
                organisationAddress: "",
                dateOfExam: "",
                placeOfExam: "",
                examQaulification: "",
                examLicenseNo: "",
                signOfExaminer: "",
                stampOfExaminer: ""
            }

        }
    },
    components: {
        Select2,
        Footer
    },
    validations: {
        formD3: {
            fNameMedicalExam: {
                required: helpers.withMessage("This field is required", required),
            },
            telephoneNo: {
                required: helpers.withMessage("This field is required", required),
            },
            faxNo: {
                required: helpers.withMessage("This field is required", required),
            },
            examEmail: {
                required: helpers.withMessage("This field is required", required),
            },
            organisationAddress: {
                required: helpers.withMessage("This field is required", required),
            },
            dateOfExam: {
                required: helpers.withMessage("This field is required", required),
            },
            placeOfExam: {
                required: helpers.withMessage("This field is required", required),
            },
            examQaulification: {
                required: helpers.withMessage("This field is required", required),
            },
            examLicenseNo: {
                required: helpers.withMessage("This field is required", required),
            },
            // signOfExaminer: {
            //     required: helpers.withMessage("This field is required", required),
            // },
            // stampOfExaminer: {
            //     required: helpers.withMessage("This field is required", required),
            // }
            healthProblemsCk: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            hospitalisedCk: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            visitDocCk: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            Tuberculosis: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            leprosy: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            hepititis: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            typhoid: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            aids: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            geneticDisorder: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            earDisease: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            eyeDisease: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            cancerousDisease: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            headache: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            mentalDisorders: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            allergies: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            hypertension: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            intestinalDisease: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            communicableDisease: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            urinaryDisease: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            venerealDisease: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            boneDisease: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            isSkinDisease: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            cosmeticOperation: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            otherDisorder: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            signSkinDisease: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            signRespiratorySys: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            signCardiovascularSys: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            signDigestiveOrgans: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            signNervousSys: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            signUrogenital: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            // urogenitalSugar: {
            //     required: helpers.withMessage("Please check a checkbox ", required),
            // },
            // urogenitalSediment: {
            //     required: helpers.withMessage("Please check a checkbox ", required),
            // },
            signMusculoskeletalSys: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            signEndocrineSys: {
                required: helpers.withMessage("Please check a checkbox ", required),
            },
            signVarious: {
                required: helpers.withMessage("Please check a checkbox", required),
            },
        }
    },
    // emits: ['select'], // Declare the emitted event 'select'
    created() {
        this.getFormD3();
        // this.getPersonalForm();
    },
    beforeMount() {
        this.getFormD3();
    },

    methods: {
        addFormD3() {
            this.submitted = true;
            this.v$.$touch();
            if (this.v$.$invalid) {
                return false;
            } else {
                let data = new FormData();
                data.append('user_id', atob(decodeURIComponent(JSON.parse(localStorage.getItem('authenticatedUserId')))));
                data.append('disclosure_id', this.decode(this.$route.params.id));
                data.append('signOfExaminer', this.signOfExaminer);
                data.append('stampOfExaminer', this.stampOfExaminer);
                _.each(this.formD3, (value, key) => {
                    data.append(key, value);
                });
                axios.post(this.app_URL + 'api/addFormD3', data, { responseType: 'arraybuffer' }).then((res) => {
                    let blob = new Blob([res.data], { type: 'application/pdf' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'FormD3.pdf';
                    link.click();
                    this.$store.state.isAccepted = true;
                    this.$store.state.notificationData = 'Form D3 Saved and Downloaded Successfully';
                    setTimeout(() => {
                        this.$store.state.isAccepted = false;
                        this.$store.state.notificationData = null;
                    }, 2500)
                    this.$router.push({path:'/citizenship_application'});
                });

            }
        },
        signOf(event) {
            this.signOfExaminer = event.target.files[0]
        },
        stampOf(event) {
            this.stampOfExaminer = event.target.files[0]
        },
        getFormD3() {
            axios.post(this.app_URL + 'api/getFormD', { disclosure_id: this.decode(this.$route.params.id) }).then(res => {
                if (res.data) {
                    this.disclosureForm = res.data;
                    this.disclosureForm.fullName = res.data.firstName + " " + res.data.surName;
                    this.disclosureForm.gender = res.data.user.gender ?? "";
                    this.disclosureForm.countryBirth = res.data.user.countryBirth ?? "";
                    this.disclosureForm.email = res.data.user.emailAddress ?? "";
                    this.disclosureForm.height = res.data.user.heightCm ?? "";
                    this.disclosureForm.weight = res.data.user.weightKg ?? "";
                    this.disclosureForm.residentialAddress = res.data.user.permanentFullAddress ?? "";
                    this.disclosureForm.maritalStatus = res.data.family.relationshipStatus ?? "";
                    this.disclosureForm.occupation = res.data.employe ? res.data.employe.currentPrimaryOcc : "";
                }
            })
        },
        downlaodFormD3() {
            axios.post(this.app_URL + "api/downlaodFormD3", { disclosure_id: this.decode(this.$route.params.id) }).then(res => {
                console.log(res);
            })
        },
        decode(id) {
            return decodeURIComponent(
                CryptoJS.AES.decrypt(String(id), "Secret Passphrase").toString(
                    CryptoJS.enc.Utf8
                )
            );
        }

    }
}
</script>
<template>
    <main class="pt-5 pb-3">
        <div class="container">
            <div class="card memberStep">
                <form>
                    <div class="card-body">
                        <div class="pb-4 text-center">
                            <img src="@/assets/images/citizenship/whinchester.png" alt="Logo whinchester" height="60" />
                        </div>
                        <h2 class="mainTitleSize">D3. MEDICAL QUESTIONNAIRE</h2>
                        <div class="">
                            <div class="">
                                <label>This Medical Questionnaire is to be completed in English by a Registered Medical
                                    Practitioner. Any additional information can be submitted on a separate sheet of paper.
                                    The
                                    Medical Practitioner must ask for evidence of identification (such as a
                                    passport)</label>
                            </div>
                            <div class="main-from-group my-4">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-lg-12 mb-3">
                                                <div class="d-block mb-3">
                                                    <label class="font-size-14">Full Name</label>
                                                    <input type="text" disabled v-model="disclosureForm.fullName"
                                                        class="form-control" placeholder="Full Name">

                                                </div>
                                                <div class="d-block mb-3">
                                                    <label class="font-size-14">Residential Address</label>
                                                    <input type="text" disabled v-model="disclosureForm.residentialAddress"
                                                        class="form-control" placeholder="Residential Address">

                                                </div>
                                                <div class="d-block  mb-3">
                                                    <label class="font-size-14">Country of Issuance</label>
                                                    <div class="single-select2-cstm">
                                                        <Select2 disabled="true" v-model="disclosureForm.countryIssuance"
                                                            placeholder="Select" :options="countryOption" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="d-block mb-3">
                                                    <label class="font-size-14">Date Of Birth</label>
                                                    <input type="date" disabled v-model="disclosureForm.dateOfBirth"
                                                        class="form-control">

                                                </div>
                                                <div class="d-block mb-3">
                                                    <label class="font-size-14">Place and country of birth</label>
                                                    <input type="number" disabled v-model="disclosureForm.countryBirth"
                                                        class="form-control" placeholder="Place and country of birth">

                                                </div>
                                                <div class="d-block mb-3">
                                                    <label class="font-size-14">Occupation</label>
                                                    <input type="text" disabled v-model="disclosureForm.occupation"
                                                        class="form-control" placeholder="Occupation">

                                                </div>
                                                <div class="d-block mb-3">
                                                    <label class="font-size-14">Marital Status</label>
                                                    <input type="text" disabled v-model="disclosureForm.maritalStatus"
                                                        class="form-control" placeholder="">

                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="d-block mb-4">
                                                    <label class="font-size-14">Gender</label>
                                                    <div class="row">
                                                        <div class="col-auto">
                                                            <label for="maleRd"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" disabled name="genderStatus"
                                                                    class="inputRadioCstm d-none"
                                                                    v-model="disclosureForm.gender" value="M" id="maleRd">
                                                                <span class="radioCircleIcon">
                                                                    <i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                    <i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                                </span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Male
                                                                </h4>
                                                            </label>
                                                        </div>
                                                        <div class="col-auto">
                                                            <label for="femaleRd"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" disabled v-model="disclosureForm.gender"
                                                                    name="genderStatus" class="inputRadioCstm d-none"
                                                                    value="F" id="femaleRd"><span class="radioCircleIcon"><i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Female
                                                                </h4>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="d-block  mb-3">
                                                    <label class="font-size-14">Date and place of issue</label>
                                                    <input type="text" disabled v-model="disclosureForm.placeIssue"
                                                        class="form-control" placeholder="">

                                                </div>
                                                <div class="d-block  mb-3">
                                                    <label class="font-size-14">Height (cm)</label>
                                                    <input type="number" disabled v-model="disclosureForm.height"
                                                        class="form-control" placeholder="Height (cm)">

                                                </div>
                                                <div class="d-block  mb-3">
                                                    <label class="font-size-14">Weight (kg)</label>
                                                    <input type="number" disabled v-model="disclosureForm.weight"
                                                        class="form-control" placeholder="Weight (kg)">

                                                </div>
                                            </div>
                                            <div class="col-lg-12">

                                                <div class="d-block  mb-3">
                                                    <label class="font-size-14">Email Address</label>
                                                    <input type="email" disabled v-model="disclosureForm.email"
                                                        class="form-control" placeholder="Email Address">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="groupFormStyle mt-4">
                                    <h2 class="font-size-18 pb-2">PART A: Statement of Health</h2>
                                    <label class="font-size-14"> The Medical Examiner is requested to ask the following
                                        questions or to review them if they have been answered previously. Give details and
                                        dates if any of the questions below are answered with “Yes” </label>
                                    <div class="row">
                                        <div class="col-lg-12 mb-3">
                                            <div class="d-block mb-3">
                                                <div class="d-flex justify-content-between">
                                                    <label class="font-size-14"><span class="text-theme">1.</span> Do you
                                                        currently have any health problems?</label>
                                                    <div class="row">
                                                        <div class="col-auto">
                                                            <label for="YesRd"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.healthProblemsCk"
                                                                    name="healthStatus" class="inputRadioCstm d-none"
                                                                    :value="true"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.healthProblemsCk.$error, }"
                                                                    id="YesRd">

                                                                <span class="radioCircleIcon">
                                                                    <i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                    <i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                                </span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                            </label>
                                                        </div>
                                                        <div class="col-auto">
                                                            <label for="NoRd"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.healthProblemsCk"
                                                                    name="healthStatus" class="inputRadioCstm d-none"
                                                                    :value="false"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.healthProblemsCk.$error, }"
                                                                    id="NoRd"><span class="radioCircleIcon"><i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <span class="text-danger"
                                                        v-if="submitted && v$.formD3.healthProblemsCk.$error">{{
                                                            v$.formD3.healthProblemsCk.required.$message }}</span>

                                                </div>
                                                <input type="text" v-model="formD3.healthProblems" class="form-control">
                                            </div>
                                            <div class="d-block mb-3">
                                                <div class="d-flex justify-content-between">
                                                    <label class="font-size-14"><span class="text-theme">2.</span> Have you
                                                        ever
                                                        been hospitalised?</label>
                                                    <div class="row">
                                                        <div class="col-auto">
                                                            <label for="hospitalisedYes"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.hospitalisedCk"
                                                                    name="hospitalised" class="inputRadioCstm d-none"
                                                                    :value="true"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.hospitalisedCk.$error, }"
                                                                    id="hospitalisedYes">
                                                                <span class="radioCircleIcon">
                                                                    <i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                    <i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                                </span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                            </label>
                                                        </div>
                                                        <div class="col-auto">
                                                            <label for="hospitalisedNo"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.hospitalisedCk"
                                                                    name="hospitalised" class="inputRadioCstm d-none"
                                                                    :value="false"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.hospitalisedCk.$error, }"
                                                                    id="hospitalisedNo"><span class="radioCircleIcon"><i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <span class="text-danger"
                                                        v-if="submitted && v$.formD3.hospitalisedCk.$error">{{
                                                            v$.formD3.hospitalisedCk.required.$message }}</span>
                                                </div>
                                                <input type="text" v-model="formD3.hospitalised" class="form-control">
                                            </div>
                                            <div class="d-block mb-3">
                                                <div class="d-flex justify-content-between">
                                                    <label class="font-size-14"><span class="text-theme">3.</span> Have you
                                                        visited a doctor in the last three (3) years?</label>
                                                    <div class="row">
                                                        <div class="col-auto">
                                                            <label for="doctorYes"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.visitDocCk"
                                                                    name="doctorRadio" class="inputRadioCstm d-none"
                                                                    :value="true"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.visitDocCk.$error, }"
                                                                    id="doctorYes">
                                                                <span class="radioCircleIcon">
                                                                    <i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                    <i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                                </span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                            </label>
                                                        </div>
                                                        <div class="col-auto">
                                                            <label for="doctor"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.visitDocCk"
                                                                    name="doctorRadio" class="inputRadioCstm d-none"
                                                                    :value="false"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.visitDocCk.$error, }"
                                                                    id="doctor"><span class="radioCircleIcon"><i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <span class="text-danger"
                                                        v-if="submitted && v$.formD3.visitDocCk.$error">{{
                                                            v$.formD3.visitDocCk.required.$message }}</span>
                                                </div>
                                                <input type="text" v-model="formD3.visitDoc" class="form-control">
                                            </div>
                                        </div>

                                        <label class="font-size-14"><span class="text-theme">4.</span> Do you suffer from or
                                            have you ever suffered from any of the following</label>
                                        <div class="col-6">

                                            <div class="row">
                                                <div class="col-8">
                                                    <label class="font-size-14"><span class="text-theme">a.</span>
                                                        Tuberculosis</label>
                                                </div>
                                                <div class="col-4 p-0">
                                                    <div class="row">
                                                        <div class="col-auto">
                                                            <label for="TuberculosisYes"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.Tuberculosis"
                                                                    name="Tuberculosis" class="inputRadioCstm d-none"
                                                                    :value="true"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.Tuberculosis.$error, }"
                                                                    id="TuberculosisYes">
                                                                <span class="radioCircleIcon">
                                                                    <i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                    <i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                                </span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                            </label>
                                                        </div>
                                                        <div class="col-auto">
                                                            <label for="Tuberculosis"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.Tuberculosis"
                                                                    name="Tuberculosis" class="inputRadioCstm d-none"
                                                                    :value="false"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.Tuberculosis.$error, }"
                                                                    id="Tuberculosis"><span class="radioCircleIcon"><i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <span class="text-danger"
                                                        v-if="submitted && v$.formD3.Tuberculosis.$error">{{
                                                            v$.formD3.Tuberculosis.required.$message }}</span>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-8">
                                                    <label class="font-size-14"><span class="text-theme">b.</span>
                                                        Leprosy</label>
                                                </div>
                                                <div class="col-4 p-0">
                                                    <div class="row">
                                                        <div class="col-auto">
                                                            <label for="LeprosyNoYes"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.leprosy"
                                                                    name="LeprosyRadio" class="inputRadioCstm d-none"
                                                                    :value="true"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.leprosy.$error, }"
                                                                    id="LeprosyNoYes">
                                                                <span class="radioCircleIcon">
                                                                    <i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                    <i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                                </span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                            </label>
                                                        </div>
                                                        <div class="col-auto">
                                                            <label for="LeprosyNo"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.leprosy"
                                                                    name="LeprosyRadio" class="inputRadioCstm d-none"
                                                                    :value="false"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.leprosy.$error, }"
                                                                    id="LeprosyNo"><span class="radioCircleIcon"><i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <span class="text-danger"
                                                        v-if="submitted && v$.formD3.leprosy.$error">{{
                                                            v$.formD3.leprosy.required.$message }}</span>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-8">
                                                    <label class="font-size-14"><span class="text-theme">c.</span> Hepititis
                                                        (specify type)</label>
                                                </div>
                                                <div class="col-4 p-0">
                                                    <div class="row">
                                                        <div class="col-auto">
                                                            <label for="HepititisYes"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.hepititis"
                                                                    name="HepititisRadio" class="inputRadioCstm d-none"
                                                                    :value="true"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.hepititis.$error, }"
                                                                    id="HepititisYes">
                                                                <span class="radioCircleIcon">
                                                                    <i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                    <i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                                </span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                            </label>
                                                        </div>
                                                        <div class="col-auto">
                                                            <label for="HepititisNo"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.hepititis"
                                                                    name="HepititisRadio" class="inputRadioCstm d-none"
                                                                    :value="false"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.hepititis.$error, }"
                                                                    id="HepititisNo"><span class="radioCircleIcon"><i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <span class="text-danger"
                                                        v-if="submitted && v$.formD3.hepititis.$error">{{
                                                            v$.formD3.hepititis.required.$message }}</span>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-8">
                                                    <label class="font-size-14"><span class="text-theme">D.</span> Typhoid,
                                                        dysentery or any other infectious or communicable diseases</label>
                                                </div>
                                                <div class="col-4 p-0">
                                                    <div class="row">
                                                        <div class="col-auto">
                                                            <label for="dysenteryYes"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.typhoid"
                                                                    name="dysenteryRadio" class="inputRadioCstm d-none"
                                                                    :value="true"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.typhoid.$error, }"
                                                                    id="dysenteryYes">
                                                                <span class="radioCircleIcon">
                                                                    <i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                    <i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                                </span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                            </label>
                                                        </div>
                                                        <div class="col-auto">
                                                            <label for="dysenteryNo"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.typhoid"
                                                                    name="dysenteryRadio" class="inputRadioCstm d-none"
                                                                    :value="false"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.typhoid.$error, }"
                                                                    id="dysenteryNo"><span class="radioCircleIcon"><i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <span class="text-danger"
                                                        v-if="submitted && v$.formD3.typhoid.$error">{{
                                                            v$.formD3.typhoid.required.$message }}</span>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-8">
                                                    <label class="font-size-14"><span class="text-theme">e.</span> AIDS or
                                                        AIDS
                                                        related conditions, any Immune Deficiency Syndrome</label>
                                                </div>
                                                <div class="col-4 p-0">
                                                    <div class="row">
                                                        <div class="col-auto">
                                                            <label for="AIDSYes"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.aids" name="AIDSRadio"
                                                                    class="inputRadioCstm d-none" :value="true"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.aids.$error, }"
                                                                    id="AIDSYes">
                                                                <span class="radioCircleIcon">
                                                                    <i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                    <i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                                </span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                            </label>
                                                        </div>
                                                        <div class="col-auto">
                                                            <label for="AIDSNo"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.aids" name="AIDSRadio"
                                                                    class="inputRadioCstm d-none" :value="false"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.aids.$error, }"
                                                                    id="AIDSNo"><span class="radioCircleIcon"><i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <span class="text-danger" v-if="submitted && v$.formD3.aids.$error">{{
                                                        v$.formD3.aids.required.$message }}</span>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-8">
                                                    <label class="font-size-14"><span class="text-theme">f.</span> Genetic
                                                        or
                                                        Familial Disorders</label>
                                                </div>
                                                <div class="col-4 p-0">
                                                    <div class="row">
                                                        <div class="col-auto">
                                                            <label for="GeneticYes"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.geneticDisorder"
                                                                    name="GeneticRadio" class="inputRadioCstm d-none"
                                                                    :value="true"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.geneticDisorder.$error, }"
                                                                    id="GeneticYes">
                                                                <span class="radioCircleIcon">
                                                                    <i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                    <i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                                </span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                            </label>
                                                        </div>
                                                        <div class="col-auto">
                                                            <label for="GeneticNo"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.geneticDisorder"
                                                                    name="GeneticRadio" class="inputRadioCstm d-none"
                                                                    :value="false"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.geneticDisorder.$error, }"
                                                                    id="GeneticNo"><span class="radioCircleIcon"><i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <span class="text-danger"
                                                        v-if="submitted && v$.formD3.geneticDisorder.$error">{{
                                                            v$.formD3.geneticDisorder.required.$message }}</span>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-8">
                                                    <label class="font-size-14"><span class="text-theme">g.</span> Deafness
                                                        or
                                                        Chronic Ear Disease</label>
                                                </div>
                                                <div class="col-4 p-0">
                                                    <div class="row">
                                                        <div class="col-auto">
                                                            <label for="DeafnessYes"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.earDisease"
                                                                    name="DeafnessRadio" class="inputRadioCstm d-none"
                                                                    :value="true"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.earDisease.$error, }"
                                                                    id="DeafnessYes">
                                                                <span class="radioCircleIcon">
                                                                    <i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                    <i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                                </span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                            </label>
                                                        </div>
                                                        <div class="col-auto">
                                                            <label for="DeafnessNo"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.earDisease"
                                                                    name="DeafnessRadio" class="inputRadioCstm d-none"
                                                                    :value="false"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.earDisease.$error, }"
                                                                    id="DeafnessNo"><span class="radioCircleIcon"><i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <span class="text-danger"
                                                        v-if="submitted && v$.formD3.earDisease.$error">{{
                                                            v$.formD3.earDisease.required.$message }}</span>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-8">
                                                    <label class="font-size-14"><span class="text-theme">h.</span> Blindness
                                                        or
                                                        Eye Disease</label>
                                                </div>
                                                <div class="col-4 p-0">
                                                    <div class="row">
                                                        <div class="col-auto">
                                                            <label for="BlindnessYes"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.eyeDisease"
                                                                    name="BlindnessRadio" class="inputRadioCstm d-none"
                                                                    :value="true"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.eyeDisease.$error, }"
                                                                    id="BlindnessYes">
                                                                <span class="radioCircleIcon">
                                                                    <i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                    <i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                                </span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                            </label>
                                                        </div>
                                                        <div class="col-auto">
                                                            <label for="BlindnessNo"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.eyeDisease"
                                                                    name="BlindnessRadio" class="inputRadioCstm d-none"
                                                                    :value="false"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.eyeDisease.$error, }"
                                                                    id="BlindnessNo"><span class="radioCircleIcon"><i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <span class="text-danger"
                                                        v-if="submitted && v$.formD3.eyeDisease.$error">{{
                                                            v$.formD3.eyeDisease.required.$message }}</span>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-8">
                                                    <label class="font-size-14"><span class="text-theme">i.</span> Any
                                                        cancerous
                                                        disease: benign / malignant</label>
                                                </div>
                                                <div class="col-4 p-0">
                                                    <div class="row">
                                                        <div class="col-auto">
                                                            <label for="cancerousYes"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.cancerousDisease"
                                                                    name="cancerousRadio" class="inputRadioCstm d-none"
                                                                    :value="true"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.cancerousDisease.$error, }"
                                                                    id="cancerousYes">
                                                                <span class="radioCircleIcon">
                                                                    <i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                    <i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                                </span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                            </label>
                                                        </div>
                                                        <div class="col-auto">
                                                            <label for="cancerousNo"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.cancerousDisease"
                                                                    name="cancerousRadio" class="inputRadioCstm d-none"
                                                                    :value="false"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.cancerousDisease.$error, }"
                                                                    id="cancerousNo"><span class="radioCircleIcon"><i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <span class="text-danger"
                                                        v-if="submitted && v$.formD3.cancerousDisease.$error">{{
                                                            v$.formD3.cancerousDisease.required.$message }}</span>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-8">
                                                    <label class="font-size-14"><span class="text-theme">j.</span> Headache,
                                                        migraine, epilepsy or dizziness</label>
                                                </div>
                                                <div class="col-4 p-0">
                                                    <div class="row">
                                                        <div class="col-auto">
                                                            <label for="HeadacheYes"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.headache"
                                                                    name="HeadacheRadio" class="inputRadioCstm d-none"
                                                                    :value="true"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.headache.$error, }"
                                                                    id="HeadacheYes">
                                                                <span class="radioCircleIcon">
                                                                    <i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                    <i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                                </span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                            </label>
                                                        </div>
                                                        <div class="col-auto">
                                                            <label for="HeadacheNo"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.headache"
                                                                    name="HeadacheRadio" class="inputRadioCstm d-none"
                                                                    :value="false"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.headache.$error, }"
                                                                    id="HeadacheNo"><span class="radioCircleIcon"><i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <span class="text-danger"
                                                        v-if="submitted && v$.formD3.headache.$error">{{
                                                            v$.formD3.headache.required.$message }}</span>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-8">
                                                    <label class="font-size-14"><span class="text-theme">k.</span> Nervous
                                                        or
                                                        mental illness or disorders</label>
                                                </div>
                                                <div class="col-4 p-0">
                                                    <div class="row">
                                                        <div class="col-auto">
                                                            <label for="NervousYes"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.mentalDisorders"
                                                                    name="NervousRadio" class="inputRadioCstm d-none"
                                                                    :value="true"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.mentalDisorders.$error, }"
                                                                    id="NervousYes">
                                                                <span class="radioCircleIcon">
                                                                    <i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                    <i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                                </span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                            </label>
                                                        </div>
                                                        <div class="col-auto">
                                                            <label for="NervousNo"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.mentalDisorders"
                                                                    name="NervousRadio" class="inputRadioCstm d-none"
                                                                    :value="false"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.mentalDisorders.$error, }"
                                                                    id="NervousNo"><span class="radioCircleIcon"><i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <span class="text-danger"
                                                        v-if="submitted && v$.formD3.mentalDisorders.$error">{{
                                                            v$.formD3.mentalDisorders.required.$message }}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-6">
                                            <div class="row">
                                                <div class="col-8">
                                                    <label class="font-size-14"><span class="text-theme">l.</span> Any
                                                        allergies, asthma or pulmonary disease</label>
                                                </div>
                                                <div class="col-4 p-0">
                                                    <div class="row">
                                                        <div class="col-auto">
                                                            <label for="allergiesYes"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.allergies"
                                                                    name="allergiesRadio" class="inputRadioCstm d-none"
                                                                    :value="true"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.allergies.$error, }"
                                                                    id="allergiesYes">
                                                                <span class="radioCircleIcon">
                                                                    <i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                    <i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                                </span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                            </label>
                                                        </div>
                                                        <div class="col-auto">
                                                            <label for="allergiesNo"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.allergies"
                                                                    name="allergiesRadio" class="inputRadioCstm d-none"
                                                                    :value="false"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.allergies.$error, }"
                                                                    id="allergiesNo"><span class="radioCircleIcon"><i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <span class="text-danger"
                                                        v-if="submitted && v$.formD3.allergies.$error">{{
                                                            v$.formD3.allergies.required.$message }}</span>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-8">
                                                    <label class="font-size-14"><span class="text-theme">m.</span>
                                                        Cardiovascular diseases, arterial hypertension</label>
                                                </div>
                                                <div class="col-4 p-0">
                                                    <div class="row">
                                                        <div class="col-auto">
                                                            <label for="hypertensionYes"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.hypertension"
                                                                    name="hypertensionRadio" class="inputRadioCstm d-none"
                                                                    :value="true"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.hypertension.$error, }"
                                                                    id="hypertensionYes">
                                                                <span class="radioCircleIcon">
                                                                    <i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                    <i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                                </span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                            </label>
                                                        </div>
                                                        <div class="col-auto">
                                                            <label for="hypertensionNo"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.hypertension"
                                                                    name="hypertensionRadio" class="inputRadioCstm d-none"
                                                                    :value="false"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.hypertension.$error, }"
                                                                    id="hypertensionNo"><span class="radioCircleIcon"><i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <span class="text-danger"
                                                        v-if="submitted && v$.formD3.hypertension.$error">{{
                                                            v$.formD3.hypertension.required.$message }}</span>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-8">
                                                    <label class="font-size-14"><span class="text-theme">n.</span> Liver,
                                                        stomach or intestinal diseases</label>
                                                </div>
                                                <div class="col-4 p-0">
                                                    <div class="row">
                                                        <div class="col-auto">
                                                            <label for="LiverYes"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.intestinalDisease"
                                                                    name="LiverRadio" class="inputRadioCstm d-none"
                                                                    :value="true"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.intestinalDisease.$error, }"
                                                                    id="LiverYes">
                                                                <span class="radioCircleIcon">
                                                                    <i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                    <i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                                </span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                            </label>
                                                        </div>
                                                        <div class="col-auto">
                                                            <label for="LiverNo"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.intestinalDisease"
                                                                    name="LiverRadio" class="inputRadioCstm d-none"
                                                                    :value="false"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.intestinalDisease.$error, }"
                                                                    id="LiverNo"><span class="radioCircleIcon"><i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <span class="text-danger"
                                                        v-if="submitted && v$.formD3.intestinalDisease.$error">{{
                                                            v$.formD3.intestinalDisease.required.$message }}</span>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-8">
                                                    <label class="font-size-14"><span class="text-theme">o.</span>Typhoid,
                                                        dysentery or any other infectious or communicable diseases </label>
                                                </div>
                                                <div class="col-4 p-0">
                                                    <div class="row">
                                                        <div class="col-auto">
                                                            <label for="TyphoidYes"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.communicableDisease"
                                                                    name="TyphoidRadio" class="inputRadioCstm d-none"
                                                                    :value="true"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.communicableDisease.$error, }"
                                                                    id="TyphoidYes">
                                                                <span class="radioCircleIcon">
                                                                    <i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                    <i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                                </span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                            </label>
                                                        </div>
                                                        <div class="col-auto">
                                                            <label for="TyphoidNo"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.communicableDisease"
                                                                    name="TyphoidRadio" class="inputRadioCstm d-none"
                                                                    :value="false"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.communicableDisease.$error, }"
                                                                    id="TyphoidNo"><span class="radioCircleIcon"><i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <span class="text-danger"
                                                        v-if="submitted && v$.formD3.communicableDisease.$error">{{
                                                            v$.formD3.communicableDisease.required.$message }}</span>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-8">
                                                    <label class="font-size-14"><span class="text-theme">p.</span> Urinary
                                                        tract
                                                        disease</label>
                                                </div>
                                                <div class="col-4 p-0">
                                                    <div class="row">
                                                        <div class="col-auto">
                                                            <label for="UrinaryYes"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.urinaryDisease"
                                                                    name="UrinaryRadio" class="inputRadioCstm d-none"
                                                                    :value="true"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.urinaryDisease.$error, }"
                                                                    id="UrinaryYes">
                                                                <span class="radioCircleIcon">
                                                                    <i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                    <i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                                </span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                            </label>
                                                        </div>
                                                        <div class="col-auto">
                                                            <label for="UrinaryNo"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.urinaryDisease"
                                                                    name="UrinaryRadio" class="inputRadioCstm d-none"
                                                                    :value="false"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.urinaryDisease.$error, }"
                                                                    id="UrinaryNo"><span class="radioCircleIcon"><i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <span class="text-danger"
                                                        v-if="submitted && v$.formD3.urinaryDisease.$error">{{
                                                            v$.formD3.urinaryDisease.required.$message }}</span>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-8">
                                                    <label class="font-size-14"><span class="text-theme">q.</span> Venereal
                                                        diseases</label>
                                                </div>
                                                <div class="col-4 p-0">
                                                    <div class="row">
                                                        <div class="col-auto">
                                                            <label for="VenerealYes"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.venerealDisease"
                                                                    name="VenerealRadio" class="inputRadioCstm d-none"
                                                                    :value="true"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.venerealDisease.$error, }"
                                                                    id="VenerealYes">
                                                                <span class="radioCircleIcon">
                                                                    <i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                    <i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                                </span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                            </label>
                                                        </div>
                                                        <div class="col-auto">
                                                            <label for="VenerealNo"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.venerealDisease"
                                                                    name="VenerealRadio" class="inputRadioCstm d-none"
                                                                    :value="false"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.venerealDisease.$error, }"
                                                                    id="VenerealNo"><span class="radioCircleIcon"><i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <span class="text-danger"
                                                        v-if="submitted && v$.formD3.venerealDisease.$error">{{
                                                            v$.formD3.venerealDisease.required.$message }}</span>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-8">
                                                    <label class="font-size-14"><span class="text-theme">r.</span>
                                                        Rheumatism,
                                                        Muscle, Joint or bone diseases</label>
                                                </div>
                                                <div class="col-4 p-0">
                                                    <div class="row">
                                                        <div class="col-auto">
                                                            <label for="MuscleYes"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.boneDisease"
                                                                    name="MuscleRadio" class="inputRadioCstm d-none"
                                                                    :value="true"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.boneDisease.$error, }"
                                                                    id="MuscleYes">
                                                                <span class="radioCircleIcon">
                                                                    <i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                    <i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                                </span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                            </label>
                                                        </div>
                                                        <div class="col-auto">
                                                            <label for="MuscleNo"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.boneDisease"
                                                                    name="MuscleRadio" class="inputRadioCstm d-none"
                                                                    :value="false"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.boneDisease.$error, }"
                                                                    id="MuscleNo"><span class="radioCircleIcon"><i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <span class="text-danger"
                                                        v-if="submitted && v$.formD3.boneDisease.$error">{{
                                                            v$.formD3.boneDisease.required.$message }}</span>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-8">
                                                    <label class="font-size-14"><span class="text-theme">s.</span> Skin
                                                        diseases</label>
                                                </div>
                                                <div class="col-4 p-0">
                                                    <div class="row">
                                                        <div class="col-auto">
                                                            <label for="SkinYes"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.isSkinDisease"
                                                                    name="SkinRadio" class="inputRadioCstm d-none"
                                                                    :value="true"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.isSkinDisease.$error, }"
                                                                    id="SkinYes">
                                                                <span class="radioCircleIcon">
                                                                    <i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                    <i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                                </span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                            </label>
                                                        </div>
                                                        <div class="col-auto">
                                                            <label for="SkinNo"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.isSkinDisease"
                                                                    name="SkinRadio" class="inputRadioCstm d-none"
                                                                    :value="false"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.isSkinDisease.$error, }"
                                                                    id="SkinNo"><span class="radioCircleIcon"><i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <span class="text-danger"
                                                        v-if="submitted && v$.formD3.isSkinDisease.$error">{{
                                                            v$.formD3.isSkinDisease.required.$message }}</span>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-8">
                                                    <label class="font-size-14"><span class="text-theme">t.</span> Cosmetic
                                                        operations</label>
                                                </div>
                                                <div class="col-4 p-0">
                                                    <div class="row">
                                                        <div class="col-auto">
                                                            <label for="CosmeticYes"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.cosmeticOperation"
                                                                    name="CosmeticRadio" class="inputRadioCstm d-none"
                                                                    :value="true"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.cosmeticOperation.$error, }"
                                                                    id="CosmeticYes">
                                                                <span class="radioCircleIcon">
                                                                    <i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                    <i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                                </span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                            </label>
                                                        </div>
                                                        <div class="col-auto">
                                                            <label for="CosmeticNo"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.cosmeticOperation"
                                                                    name="CosmeticRadio" class="inputRadioCstm d-none"
                                                                    :value="false"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.cosmeticOperation.$error, }"
                                                                    id="CosmeticNo"><span class="radioCircleIcon"><i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <span class="text-danger"
                                                        v-if="submitted && v$.formD3.cosmeticOperation.$error">{{
                                                            v$.formD3.cosmeticOperation.required.$message }}</span>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-8">
                                                    <label class="font-size-14"><span class="text-theme">u.</span> Any other
                                                        illness or disorder</label>
                                                </div>
                                                <div class="col-4 p-0">
                                                    <div class="row">
                                                        <div class="col-auto">
                                                            <label for="otherYes"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.otherDisorder"
                                                                    name="otherRadio" class="inputRadioCstm d-none"
                                                                    :value="true"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.otherDisorder.$error, }"
                                                                    id="otherYes">
                                                                <span class="radioCircleIcon">
                                                                    <i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                    <i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                                </span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                            </label>
                                                        </div>
                                                        <div class="col-auto">
                                                            <label for="otherNo"
                                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                                <input type="radio" v-model="formD3.otherDisorder"
                                                                    name="otherRadio" class="inputRadioCstm d-none"
                                                                    :value="false"
                                                                    :class="{ 'is-invalid': submitted && v$.formD3.otherDisorder.$error, }"
                                                                    id="otherNo"><span class="radioCircleIcon"><i
                                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <span class="text-danger"
                                                        v-if="submitted && v$.formD3.otherDisorder.$error">{{
                                                            v$.formD3.otherDisorder.required.$message }}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="d-block mb-3">
                                            <label class="font-size-14">If “Yes” to any of the above, please give details
                                                and
                                                dates.</label>
                                            <textarea type="textarea" v-model="formD3.aboveDetails"
                                                class="form-control resize-none" rows="5" placeholder=""></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="row pt-4">
                                    <div class="col-12">
                                        <h2 class="font-size-18 pb-2">Part B: Medical Examination</h2>
                                        <label class="font-size-14"> If “Yes” to any of the below, please give details and
                                            dates. </label>

                                        <div class="d-block mb-3">
                                            <div class="d-flex justify-content-between">
                                                <label class="font-size-14 m-0"><span class="text-theme">5.</span>Skin - Are
                                                    there any signs of skin disease?</label>
                                                <div class="row">
                                                    <div class="col-auto">
                                                        <label for="thereYes"
                                                            class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2 m-0">
                                                            <input type="radio" v-model="formD3.signSkinDisease"
                                                                name="thereRadio" class="inputRadioCstm d-none"
                                                                :value="true"
                                                                :class="{ 'is-invalid': submitted && v$.formD3.signSkinDisease.$error, }"
                                                                id="thereYes">
                                                            <span class="radioCircleIcon">
                                                                <i
                                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                <i
                                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                            </span>
                                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                        </label>
                                                    </div>
                                                    <div class="col-auto">
                                                        <label for="thereNo"
                                                            class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2 m-0">
                                                            <input type="radio" v-model="formD3.signSkinDisease"
                                                                name="thereRadio" class="inputRadioCstm d-none"
                                                                :value="false"
                                                                :class="{ 'is-invalid': submitted && v$.formD3.signSkinDisease.$error, }"
                                                                id="thereNo"><span class="radioCircleIcon"><i
                                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                        </label>
                                                    </div>
                                                </div>
                                                <span class="text-danger"
                                                    v-if="submitted && v$.formD3.signSkinDisease.$error">{{
                                                        v$.formD3.signSkinDisease.required.$message }}</span>
                                            </div>
                                            <input type="text" v-model="formD3.skinDisease" class="form-control">
                                        </div>
                                        <div class="d-block mb-3">
                                            <div class="d-flex justify-content-between">
                                                <label class="font-size-14 m-0"><span
                                                        class="text-theme">6.</span>Respiratory
                                                    System - Any signs of abnormalities, (Including nose and lungs)?</label>
                                                <div class="row">
                                                    <div class="col-auto">
                                                        <label for="RespiratoryYes"
                                                            class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2 m-0">
                                                            <input type="radio" v-model="formD3.signRespiratorySys"
                                                                name="RespiratoryRadio" class="inputRadioCstm d-none"
                                                                :value="true"
                                                                :class="{ 'is-invalid': submitted && v$.formD3.signRespiratorySys.$error, }"
                                                                id="RespiratoryYes">
                                                            <span class="radioCircleIcon">
                                                                <i
                                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                <i
                                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                            </span>
                                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                        </label>
                                                    </div>
                                                    <div class="col-auto">
                                                        <label for="RespiratoryNo"
                                                            class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2 m-0">
                                                            <input type="radio" v-model="formD3.signRespiratorySys"
                                                                name="RespiratoryRadio" class="inputRadioCstm d-none"
                                                                :value="false"
                                                                :class="{ 'is-invalid': submitted && v$.formD3.signRespiratorySys.$error, }"
                                                                id="RespiratoryNo"><span class="radioCircleIcon"><i
                                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                        </label>
                                                    </div>
                                                </div>
                                                <span class="text-danger"
                                                    v-if="submitted && v$.formD3.signRespiratorySys.$error">{{
                                                        v$.formD3.signRespiratorySys.required.$message }}</span>
                                            </div>
                                            <input type="text" v-model="formD3.respiratorySys" class="form-control">
                                        </div>
                                        <div class="d-block mb-3">
                                            <div class="d-flex justify-content-between">
                                                <label class="font-size-14 m-0"><span class="text-theme">7.</span>
                                                    Cardiovascular System - Any signs of abnormalities, (Including pulse,
                                                    blood
                                                    pressure, heart murmurs)?</label>
                                                <div class="row">
                                                    <div class="col-auto">
                                                        <label for="CardiovascularYes"
                                                            class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2 m-0">
                                                            <input type="radio" v-model="formD3.signCardiovascularSys"
                                                                name="CardiovascularRadio" class="inputRadioCstm d-none"
                                                                :value="true"
                                                                :class="{ 'is-invalid': submitted && v$.formD3.signCardiovascularSys.$error, }"
                                                                id="CardiovascularYes">
                                                            <span class="radioCircleIcon">
                                                                <i
                                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                <i
                                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                            </span>
                                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                        </label>
                                                    </div>
                                                    <div class="col-auto">
                                                        <label for="CardiovascularNo"
                                                            class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2 m-0">
                                                            <input type="radio" v-model="formD3.signCardiovascularSys"
                                                                name="CardiovascularRadio" class="inputRadioCstm d-none"
                                                                :value="false"
                                                                :class="{ 'is-invalid': submitted && v$.formD3.signCardiovascularSys.$error, }"
                                                                id="CardiovascularNo"><span class="radioCircleIcon"><i
                                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                        </label>
                                                    </div>
                                                </div>
                                                <span class="text-danger"
                                                    v-if="submitted && v$.formD3.signCardiovascularSys.$error">{{
                                                        v$.formD3.signCardiovascularSys.required.$message }}</span>
                                            </div>
                                            <input type="text" v-model="formD3.cardiovascularSys" class="form-control">
                                        </div>
                                        <div class="d-block mb-3">
                                            <div class="d-flex justify-content-between">
                                                <label class="font-size-14 m-0"><span class="text-theme">8.</span> Digestive
                                                    Organs and abdomen - Any signs of abnormalities?</label>
                                                <div class="row">
                                                    <div class="col-auto">
                                                        <label for="DigestiveYes"
                                                            class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2 m-0">
                                                            <input type="radio" v-model="formD3.signDigestiveOrgans"
                                                                name="DigestiveRadio" class="inputRadioCstm d-none"
                                                                :value="true"
                                                                :class="{ 'is-invalid': submitted && v$.formD3.signDigestiveOrgans.$error, }"
                                                                id="DigestiveYes">
                                                            <span class="radioCircleIcon">
                                                                <i
                                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                <i
                                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                            </span>
                                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                        </label>
                                                    </div>
                                                    <div class="col-auto">
                                                        <label for="DigestiveNo"
                                                            class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2 m-0">
                                                            <input type="radio" v-model="formD3.signDigestiveOrgans"
                                                                name="DigestiveRadio" class="inputRadioCstm d-none"
                                                                :value="false"
                                                                :class="{ 'is-invalid': submitted && v$.formD3.signDigestiveOrgans.$error, }"
                                                                id="DigestiveNo"><span class="radioCircleIcon"><i
                                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                        </label>
                                                    </div>
                                                </div>
                                                <span class="text-danger"
                                                    v-if="submitted && v$.formD3.signDigestiveOrgans.$error">{{
                                                        v$.formD3.signDigestiveOrgans.required.$message }}</span>
                                            </div>
                                            <input type="text" v-model="formD3.digestiveOrgans" class="form-control">
                                        </div>
                                        <div class="d-block mb-3">
                                            <div class="d-flex justify-content-between">
                                                <label class="font-size-14 m-0"><span class="text-theme">9.</span> Nervous
                                                    System and sense organs - Any signs of abnormalities?</label>
                                                <div class="row">
                                                    <div class="col-auto">
                                                        <label for="signNervousSysYes"
                                                            class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2 m-0">
                                                            <input type="radio" v-model="formD3.signNervousSys"
                                                                name="signNervousSysRadio" class="inputRadioCstm d-none"
                                                                :value="true"
                                                                :class="{ 'is-invalid': submitted && v$.formD3.signNervousSys.$error, }"
                                                                id="signNervousSysYes">
                                                            <span class="radioCircleIcon">
                                                                <i
                                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                <i
                                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                            </span>
                                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                        </label>
                                                    </div>
                                                    <div class="col-auto">
                                                        <label for="signNervousSysNo"
                                                            class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2 m-0">
                                                            <input type="radio" v-model="formD3.signNervousSys"
                                                                name="signNervousSysRadio" class="inputRadioCstm d-none"
                                                                :value="false"
                                                                :class="{ 'is-invalid': submitted && v$.formD3.signNervousSys.$error, }"
                                                                id="signNervousSysNo"><span class="radioCircleIcon"><i
                                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                        </label>
                                                    </div>
                                                </div>
                                                <span class="text-danger"
                                                    v-if="submitted && v$.formD3.signNervousSys.$error">{{
                                                        v$.formD3.signNervousSys.required.$message }}</span>
                                            </div>
                                            <input type="text" v-model="formD3.nervousSys" class="form-control">
                                        </div>
                                        <div class="d-block mb-3">
                                            <div class="d-flex justify-content-between">
                                                <label class="font-size-14 m-0"><span class="text-theme">10.</span>
                                                    Urogenital
                                                    Organs - Any signs of abnormalities?</label>
                                                <div class="row">
                                                    <div class="col-auto">
                                                        <label for="UrogenitalYes"
                                                            class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2 m-0">
                                                            <input type="radio" v-model="formD3.signUrogenital"
                                                                name="UrogenitalRadio" class="inputRadioCstm d-none"
                                                                :value="true"
                                                                :class="{ 'is-invalid': submitted && v$.formD3.signUrogenital.$error, }"
                                                                id="UrogenitalYes">
                                                            <span class="radioCircleIcon">
                                                                <i
                                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                <i
                                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                            </span>
                                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                        </label>
                                                    </div>
                                                    <div class="col-auto">
                                                        <label for="UrogenitalNo"
                                                            class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2 m-0">
                                                            <input type="radio" v-model="formD3.signUrogenital"
                                                                name="UrogenitalRadio" class="inputRadioCstm d-none"
                                                                :value="false"
                                                                :class="{ 'is-invalid': submitted && v$.formD3.signUrogenital.$error, }"
                                                                id="UrogenitalNo"><span class="radioCircleIcon"><i
                                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                        </label>
                                                    </div>
                                                </div>
                                                <span class="text-danger"
                                                    v-if="submitted && v$.formD3.signUrogenital.$error">{{
                                                        v$.formD3.signUrogenital.required.$message }}</span>
                                            </div>
                                            <div class="row align-items-center">
                                                <div class="col-3">
                                                    <h2 class="font-size-18">Urinalysis:</h2>
                                                </div>
                                                <div class="col-3">
                                                    <label class="font-size-14">Protein</label>
                                                    <input type="text" v-model="formD3.urogenitalProtein"
                                                        class="form-control">
                                                </div>
                                                <div class="col-3">
                                                    <label class="font-size-14">Sugur</label>
                                                    <input type="text" v-model="formD3.urogenitalSugar"
                                                        class="form-control">
                                                </div>
                                                <div class="col-3">
                                                    <label class="font-size-14">Sediment</label>
                                                    <input type="text" v-model="formD3.urogenitalSediment"
                                                        class="form-control">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="d-block mb-3">
                                            <div class="d-flex justify-content-between">
                                                <label class="font-size-14 m-0"><span class="text-theme">11.</span>
                                                    Musculoskeletal System - Any signs of abnormalities?</label>
                                                <div class="row">
                                                    <div class="col-auto">
                                                        <label for="MusculoskeletalYes"
                                                            class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2 m-0">
                                                            <input type="radio" v-model="formD3.signMusculoskeletalSys"
                                                                name="MusculoskeletalRadio" class="inputRadioCstm d-none"
                                                                :value="true"
                                                                :class="{ 'is-invalid': submitted && v$.formD3.signMusculoskeletalSys.$error, }"
                                                                id="MusculoskeletalYes">
                                                            <span class="radioCircleIcon">
                                                                <i
                                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                <i
                                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                            </span>
                                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                        </label>
                                                    </div>
                                                    <div class="col-auto">
                                                        <label for="MusculoskeletalNo"
                                                            class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2 m-0">
                                                            <input type="radio" v-model="formD3.signMusculoskeletalSys"
                                                                name="MusculoskeletalRadio" class="inputRadioCstm d-none"
                                                                :value="false"
                                                                :class="{ 'is-invalid': submitted && v$.formD3.signMusculoskeletalSys.$error, }"
                                                                id="MusculoskeletalNo"><span class="radioCircleIcon"><i
                                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                        </label>
                                                    </div>
                                                </div>
                                                <span class="text-danger"
                                                    v-if="submitted && v$.formD3.signMusculoskeletalSys.$error">{{
                                                        v$.formD3.signMusculoskeletalSys.required.$message }}</span>
                                            </div>
                                            <input type="text" v-model="formD3.musculoskeletalSys" class="form-control">
                                        </div>

                                        <div class="d-block mb-3">
                                            <div class="d-flex justify-content-between">
                                                <label class="font-size-14 m-0"><span class="text-theme">12.</span>
                                                    Endocrine
                                                    System - Any signs of abnormalities, including thyroid?</label>
                                                <div class="row">
                                                    <div class="col-auto">
                                                        <label for="EndocrineYes"
                                                            class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2 m-0">
                                                            <input type="radio" v-model="formD3.signEndocrineSys"
                                                                name="EndocrineRadio" class="inputRadioCstm d-none"
                                                                :value="true"
                                                                :class="{ 'is-invalid': submitted && v$.formD3.signEndocrineSys.$error, }"
                                                                id="EndocrineYes">
                                                            <span class="radioCircleIcon">
                                                                <i
                                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                <i
                                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                            </span>
                                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                        </label>
                                                    </div>
                                                    <div class="col-auto">
                                                        <label for="EndocrineNo"
                                                            class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2 m-0">
                                                            <input type="radio" v-model="formD3.signEndocrineSys"
                                                                name="EndocrineRadio" class="inputRadioCstm d-none"
                                                                :value="false"
                                                                :class="{ 'is-invalid': submitted && v$.formD3.signEndocrineSys.$error, }"
                                                                id="EndocrineNo"><span class="radioCircleIcon"><i
                                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                        </label>
                                                    </div>
                                                </div>
                                                <span class="text-danger"
                                                    v-if="submitted && v$.formD3.signEndocrineSys.$error">{{
                                                        v$.formD3.signEndocrineSys.required.$message }}</span>
                                            </div>
                                            <input type="text" v-model="formD3.endocrineSys" class="form-control">
                                        </div>

                                        <div class="d-block mb-3">
                                            <div class="d-flex justify-content-between">
                                                <label class="font-size-14 m-0"><span class="text-theme">13.</span> Various
                                                    -
                                                    Any signs of abnormalities?</label>
                                                <div class="row">
                                                    <div class="col-auto">
                                                        <label for="VariousYes"
                                                            class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2 m-0">
                                                            <input type="radio" v-model="formD3.signVarious"
                                                                name="VariousRadio" class="inputRadioCstm d-none"
                                                                :value="true"
                                                                :class="{ 'is-invalid': submitted && v$.formD3.signVarious.$error, }"
                                                                id="VariousYes">
                                                            <span class="radioCircleIcon">
                                                                <i
                                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                                <i
                                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                                            </span>
                                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                                        </label>
                                                    </div>
                                                    <div class="col-auto">
                                                        <label for="VariousNo"
                                                            class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2 m-0">
                                                            <input type="radio" v-model="formD3.signVarious"
                                                                name="VariousRadio" class="inputRadioCstm d-none"
                                                                :value="false"
                                                                :class="{ 'is-invalid': submitted && v$.formD3.signVarious.$error, }"
                                                                id="VariousNo"><span class="radioCircleIcon"><i
                                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                                        </label>
                                                    </div>
                                                </div>
                                                <span class="text-danger"
                                                    v-if="submitted && v$.formD3.signVarious.$error">{{
                                                        v$.formD3.signVarious.required.$message }}</span>
                                            </div>
                                            <input type="text" v-model="formD3.various" class="form-control">
                                        </div>

                                        <div class="d-block mb-3">
                                            <div class="d-flex justify-content-between">
                                                <label class="font-size-14 m-0"><span class="text-theme">14.</span> Final
                                                    Evaluation</label>
                                            </div>
                                            <input type="text" v-model="formD3.finalEvaluation" class="form-control">
                                        </div>

                                        <div class="d-block mb-3">
                                            <div class="d-flex justify-content-between">
                                                <label class="font-size-14 m-0"><span class="text-theme">15.</span>
                                                    Comments</label>
                                                <!--  -->
                                            </div>
                                            <input type="text" v-model="formD3.comments" class="form-control">
                                        </div>
                                        <div class="d-block">
                                            <label class="font-size-14 m-0"><span class="text-theme">Important:</span>
                                                Medical
                                                Examiner must attach the original results of the following:</label>
                                            <p class="m-0 mt-2">i. HIV test for all applicants over 12 years old</p>
                                            <p class="m-0">ii. Routine blood and urine test</p>
                                            <p class="m-0">iii. Immunization schedule against the following:</p>
                                            <ul class="d-flex">
                                                <li class="">Diphtheria</li>
                                                <li class="ms-5">Tetanus</li>
                                                <li class="ms-5">Hepatitis</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="groupFormStyle mt-4">
                                            <h2 class="font-size-18 pb-2">Part C: Medical examiner’s details and declaration
                                            </h2>

                                            <div class="d-block mb-3">
                                                <label class="font-size-14">Full Name of Medical Examiner</label>
                                                <input type="text" v-model="formD3.fNameMedicalExam" class="form-control"
                                                    placeholder="Full Name"
                                                    :class="{ 'is-invalid': submitted && v$.formD3.fNameMedicalExam.$error, }">
                                                <div v-for="(errorMsg, index) in v$.formD3.fNameMedicalExam.$errors"
                                                    :key="index" class="invalid-feedback">
                                                    <span v-if="errorMsg.$message">{{ errorMsg.$message }}</span>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <div class="d-block mb-3">
                                                        <label class="font-size-14">Organisation Address</label>
                                                        <textarea type="textarea" v-model="formD3.organisationAddress"
                                                            class="form-control resize-none" rows="10"
                                                            placeholder=" Organisation Address"
                                                            :class="{ 'is-invalid': submitted && v$.formD3.organisationAddress.$error, }"></textarea>
                                                        <div v-for="(errorMsg, index) in v$.formD3.organisationAddress.$errors"
                                                            :key="index" class="invalid-feedback">
                                                            <span v-if="errorMsg.$message">{{ errorMsg.$message }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="d-block mb-3">
                                                        <label class="font-size-14">Telephone No </label>
                                                        <input type="number" v-model="formD3.telephoneNo"
                                                            class="form-control" placeholder="Telephone No"
                                                            :class="{ 'is-invalid': submitted && v$.formD3.telephoneNo.$error, }">
                                                        <div v-for="(errorMsg, index) in v$.formD3.telephoneNo.$errors"
                                                            :key="index" class="invalid-feedback">
                                                            <span v-if="errorMsg.$message">{{ errorMsg.$message }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="d-block mb-3">
                                                        <label class="font-size-14">Fax No.</label>
                                                        <input type="number" v-model="formD3.faxNo" class="form-control"
                                                            placeholder="Fax No"
                                                            :class="{ 'is-invalid': submitted && v$.formD3.faxNo.$error, }">
                                                        <div v-for="(errorMsg, index) in v$.formD3.faxNo.$errors"
                                                            :key="index" class="invalid-feedback">
                                                            <span v-if="errorMsg.$message">{{ errorMsg.$message }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="d-block mb-3">
                                                        <label class="font-size-14">Email Address</label>
                                                        <input type="email" v-model="formD3.examEmail" class="form-control"
                                                            placeholder="Email Address"
                                                            :class="{ 'is-invalid': submitted && v$.formD3.examEmail.$error, }">
                                                        <div v-for="(errorMsg, index) in v$.formD3.examEmail.$errors"
                                                            :key="index" class="invalid-feedback">
                                                            <span v-if="errorMsg.$message">{{ errorMsg.$message }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-block mb-3">
                                                <label class="font-size-14">I, the Medical Examiner, certify that I have
                                                    identified, questioned and examined the applicant and answered all of
                                                    the
                                                    questions and supplied all of the information to the best of my
                                                    knowledge
                                                    and in good faith.</label>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <div class="d-block mb-3">
                                                        <label class="font-size-14">Date of Examination</label>
                                                        <input type="date" v-model="formD3.dateOfExam" class="form-control"
                                                            placeholder="Date of Examination"
                                                            :class="{ 'is-invalid': submitted && v$.formD3.dateOfExam.$error, }">
                                                        <div v-for="(errorMsg, index) in v$.formD3.dateOfExam.$errors"
                                                            :key="index" class="invalid-feedback">
                                                            <span v-if="errorMsg.$message">{{ errorMsg.$message }}</span>
                                                        </div>
                                                    </div>

                                                    <div class="d-block mb-3">
                                                        <label class="font-size-14">Place of Examination</label>
                                                        <input type="text" v-model="formD3.placeOfExam" class="form-control"
                                                            placeholder="Place of Examination"
                                                            :class="{ 'is-invalid': submitted && v$.formD3.placeOfExam.$error, }">
                                                        <div v-for="(errorMsg, index) in v$.formD3.placeOfExam.$errors"
                                                            :key="index" class="invalid-feedback">
                                                            <span v-if="errorMsg.$message">{{ errorMsg.$message }}</span>
                                                        </div>
                                                    </div>

                                                    <div class="d-block mb-3">
                                                        <label class="font-size-14">Examiner’s designation /
                                                            qualification</label>
                                                        <input type="text" v-model="formD3.examQaulification"
                                                            class="form-control"
                                                            placeholder="Examiner’s designation / qualificatio"
                                                            :class="{ 'is-invalid': submitted && v$.formD3.examQaulification.$error, }">
                                                        <div v-for="(errorMsg, index) in v$.formD3.examQaulification.$errors"
                                                            :key="index" class="invalid-feedback">
                                                            <span v-if="errorMsg.$message">{{ errorMsg.$message }}</span>
                                                        </div>
                                                    </div>

                                                    <div class="d-block mb-3">
                                                        <label class="font-size-14">Examiner’s license number or
                                                            certification</label>
                                                        <input type="text" v-model="formD3.examLicenseNo"
                                                            class="form-control"
                                                            placeholder="Examiner’s license number or certification"
                                                            :class="{ 'is-invalid': submitted && v$.formD3.examLicenseNo.$error, }">
                                                        <div v-for="(errorMsg, index) in v$.formD3.examLicenseNo.$errors"
                                                            :key="index" class="invalid-feedback">
                                                            <span v-if="errorMsg.$message">{{ errorMsg.$message }}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="d-block mb-3">
                                                        <label class="font-size-14">Signature of Medical Examiner</label>
                                                        <input type="file" @change="signOf" class="form-control">
                                                        <!-- <textarea type="textarea" v-model="formD3.signOfExaminer"
                                                            class="form-control resize-none" rows="4"
                                                            placeholder="Signature of Medical Examiner"></textarea> -->
                                                    </div>
                                                    <div class="d-block mb-3">
                                                        <label class="font-size-14">Stamp of Medical Examiner</label>
                                                        <input type="file" @change="stampOf" class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="text-end">
                            <button type="button" @click="addFormD3" class="btn btn-theme">Submit</button>
                        </div>
                    </div>
                    <!-- <div>
                        <router-link type="button" to="/citizenship_application" class="btn btn-theme">Return Back</router-link>
                    </div> -->
                </form>
            </div>
        </div>
    </main>
<Footer></Footer>

</template>
